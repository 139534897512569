<template>
  <div class="more">
    <div v-if="serviceList && serviceList.length > 0">
      <ul class="main-container">
        <li v-for="(item, i) in serviceList" :key="i">
          <router-link
            :to="{}"
          >
            <div class="text">
              <h4>客服：{{ item.real_name }}</h4>
              <p>电话： {{ item.phone }}</p>
              <p>微信号： {{ item.wechat }}</p>
            </div>
          </router-link>
          <i v-show="item.qrcode_img" class="jym-saoma" @click="showIcon(item.qrcode_img)"></i>
        </li>
      </ul>
    </div>
    <div v-else class="emptyData" v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无数据</p>
    </div>
    
    <mt-popup
      v-model="popupVisible"
      position="center">
      <i class="jym-close dialog-close" @click="popupVisible = false"></i>
      <img :src="imgUrl">
    </mt-popup>
  </div>
</template>

<script>
import { userCustomer } from "@api/user";

export default {
  name: "mycare",
  data() {
    return {
      imgUrl: '',
      serviceList: [],
      popupVisible: false,
      initLoading: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let that = this;

      userCustomer().then(res => {
        that.serviceList = res.data
        that.initLoading = true
      })
      .catch(res => {
        that.$dialog.toast({ mes: res.msg });
      });
    },

    showIcon(url) {
      this.popupVisible = true
      this.imgUrl = url
    }
  }
};
</script>

<style lang="scss" scoped>
.more {
  ul {
    background: white;
    li {
      width: 100%;
      padding: 0.2rem 0.3rem .1rem .3rem;
      border-top: 0.1rem solid #f5f5f5;
      display: flex;
      justify-content: space-between;
      position: relative;
      a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        em {
          line-height: 1rem;
          color: #A9A9A9;
          font-size: .28rem;
          i{
            font-size: .36rem;
          }
        }
        .text {
          h4 {
            font-size: 0.28rem;
            margin-bottom: .1rem;
          }
          p {
            font-size: 0.28rem;
            margin-bottom: .1rem;
            span {
              color: #a9a9a9;
            }
          }
        }
      }
      i {
        position: absolute;
        font-size: .42rem;
        top: .6rem;
        right: .3rem;
      }
    }
  }
  .mint-popup {
    width: 5rem;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
